@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Lato';
}



:root {
  --bgWhite: #fff;
  --BG: #f4f2ee;
  --Border: #dfdeda;
  --BradiusImgProfile: 50%;
  --BradiusArticles: 9px;
  --TreeCTP: #666666;
  --WidthArticles: 900px;
  --paddingArticles: 25px;
  --btnBlue: #0a66c2;
}

.svg {
  width: 1.5em;
  height: 1.5em;
}


html {
  background-color: var(--BG);
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  margin-top: 9px;
  margin-bottom: 10px;
}


.Header {
  width: var(--WidthArticles);
  border: 1px solid var(--Border);
  border-radius: var(--BradiusArticles);
  overflow: hidden;

  position: relative;
}

.picTopHeader {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 200px;
}

.picTopHeader img {
  width: 100%;
  object-fit: cover;
}

.presentationHeader {
  padding: var(--paddingArticles);
  background-color: var(--bgWhite);
  padding-bottom: 17px;
}

.imgProfileContainer {
  position: absolute;
  border: 4px solid var(--bgWhite);
  width: 150px;
  border-radius: var(--BradiusImgProfile);
  display: flex;
  overflow: hidden;
  margin-top: -126px;
}

.imgProfileContainer img {
  width: 100%;
  object-fit: cover;
}

.textPresentation {
  margin-top: 30px;
  display: flex;
}

.textPresentation h1 {
  font-size: 25px;
}

.textTwoPresentation {
  font-size: 15px;
  padding-top: 5px;
}

.textTreePresentation {
  font-size: 14px;
  padding-top: 5px;
  color: var(--TreeCTP);
}

.presentationText {
  width: 70%;
}

.presentationLogos {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.presentationLogos img {
  width: 20%;
}

.presentationLogos p {
  font-weight: bold;
  font-size: 15px;
  padding-left: 5px;
}

.footerHeader {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.btnDCV {
  font-weight: bold;
  font-size: 16px;
  padding: 3px 15px 5px 15px;
  border: none;
  color: #fff;
  border-radius: 15px;
  background-color: var(--btnBlue);
  cursor: pointer;
  margin: 0;
  margin-left: 10px;
}

/*stack*/


.Stack {
  border: 1px solid var(--Border);
  background-color: var(--bgWhite);
  border-radius: var(--BradiusArticles);
  width: var(--WidthArticles);
  padding: var(--paddingArticles);
  padding-top: 17px;
}

.Stack h2 {
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 6px;
}


.techFront {
  display: flex;
  flex-direction: column;

}


.techContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--Border);
  padding-bottom: 10px;
}

.TcPT {
  padding-top: 5px;
}

.techContainer h3 {
  padding: 0px 10px 6px 5px;
  font-size: 16px;
  font-weight: 500;
  color: var(--TreeCTP);
}

.CT {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.tech {
  width: auto;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--Border);
  background-color: var(--BG);
  border-radius: 3px;
  cursor: pointer;
}

.tech:hover{
  transition: .5s;
  background-color: #c3c3c3;
}

.tech img {
  width: 40px;
}

.tech p {
  color: var(--TreeCTP);
  font-size: 13px;
  padding-left: 5px;
}


/*Projects*/

.Projects {
  border: 1px solid var(--Border);
  background-color: var(--bgWhite);
  border-radius: var(--BradiusArticles);
  width: var(--WidthArticles);
  padding: var(--paddingArticles);
}

.Projects h2 {
  font-size: 22px;
  font-weight: 500;
}

.BoxP {
  border-bottom: 1px solid var(--Border);
  padding: 10px 5px;


  position: relative;


  display: flex;
  flex-direction: column;
}

.BoxPH3 {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}


.svgDate {
  width: 10px;
  height: 10px;
}

.siteProject {
  padding-top: 3px;
}

.stackProject {
  font-size: 14px;
}

.stackContainerP {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
}

.svgLinkedin {
  width: 30px;
  height: 30px;
}

.dateContainerP {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 3px;
}

.fechaProject {
  padding-bottom: 3px;
  font-size: 14px;
  color: var(--TreeCTP);
}

.svgPP {
  width: 18px;
  height: 18px;
}

.demoProjectsA {
  display: inline-block;
  margin-top: 5px;
  font-weight: bold;
  font-size: 16px;
  padding: 4px 15px 5px 15px;
  border: none;
  color: #fff;
  border-radius: 15px;
  background-color: var(--btnBlue);
  cursor: pointer;
  text-decoration: none;

  position: absolute;
  bottom: 10px;
  right: 10px;
}



/*AboutMe*/

.AboutMe {
  border: 1px solid var(--Border);
  background-color: var(--bgWhite);
  border-radius: var(--BradiusArticles);
  width: var(--WidthArticles);
  padding: var(--paddingArticles);
  padding-top: 17px;
}

.AboutMe h2 {
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 6px;
}

.AboutMe p {
  font-size: 15px;
}



.readMore {
  background: none;
  border: none;
  color: #0009;
  /* Color azul */
  cursor: pointer;
}

.CB {
  display: flex;
  justify-content: flex-end;
}

/*contactMe*/

.containerButtonCMe {}

.containerButtonCMe button {
  font-weight: bold;
  font-size: 16px;
  padding: 3px 15px 5px 15px;
  border: none;
  color: #fff;
  border-radius: 15px;
  background-color: var(--btnBlue);
  cursor: pointer;
  margin: 0;
}


/*modal contactme*/
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 15px;
  border-radius: 12px;
  width: 400px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: fadeIn 0.5s ease;
}

.modalContent h2 {
  margin-bottom: 10px;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}



h2 {
  margin-top: 0;
  font-size: 24px;
  color: #333;
}

.containerP {
  background-color: #e9ecef;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.containerP img {
  width: 10%;
}

.containerP p {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}



@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Modal */
.modalProject {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.contantModalProject {
  background: var(--BG);
  padding: 15px;
  border-radius: 12px;
  max-width: 900px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: fadeIn 0.5s ease;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  overflow-y: auto;
  gap: 5px;


}

.contantModalProject::-webkit-scrollbar {
  width: 8px;
}

.contantModalProject::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 15px;
}

.contantModalProject::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
  padding: 10px;
}

.contantModalProject::-webkit-scrollbar-thumb:hover {
  background: var(--btnBlue);
}

.contentBtnCloseATitle {
  background-color: var(--bgWhite);
  padding: 15px;
  border: 1px solid var(--Border);
  border-radius: var(--BradiusArticles);
}

.contantModalProject h1 {
  font-weight: 500;
}

.textPCM {
  padding: 5px 0 0 0;
}

.projectDContainer {
  background-color: var(--bgWhite);
  border-radius: var(--BradiusArticles);
  border: 1px solid var(--Border);
  padding: 15px;

  position: relative;
}

.projectDContainer h3 {
  font-size: 17px;
}

.readMoreButton {
  background: none;
  border: none;
  color: #0009;
  cursor: pointer;

  position: absolute;
  right: 5px;
  bottom: 7px;
}

.closeButton {
  font-weight: bold;
  font-size: 16px;
  padding: 4px 15px;
  border: none;
  color: #fff;
  border-radius: 15px;
  background-color: var(--btnBlue);
  cursor: pointer;

  margin: 15px;
}

/* Carrusel */

.carrousellConteiner {
  display: flex;
  justify-content: center;
  background-color: var(--bgWhite);
  border: 1px solid var(--Border);
  border-radius: var(--BradiusArticles);

}

.carousel {

  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.pNIS {
  border-bottom: 1px solid var(--Border);
  padding: 10px 0 14px 0;
}


.carouselImagesContainer {
  position: relative;
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 400px;
  overflow: hidden;
  
}

.carouselImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute; 
  top: 0;
  left: 0;
  opacity: 0; 
  transition: opacity 0.5s ease-in-out; 
  z-index: 0; 
}

.carouselImage.active {
  opacity: 1; /* La imagen activa es visible */
  z-index: 1; /* La imagen activa tiene prioridad visual */
}

.carouselImage.inactive {
  opacity: 0; /* Las imágenes inactivas están ocultas */
  z-index: 0;
}


.carouselButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 10;
  padding: 0.5em;
  border-radius: 50%;

  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselButton:hover {
  background: rgba(0, 0, 0, 0.7);
}

.carouselButton:first-of-type {
  left: 10px;
}

.carouselButton:last-of-type {
  right: 10px;
}

/*education*/

.educationArticle{
  width: var(--WidthArticles);
  background-color: var(--bgWhite);
  border-radius: var(--BradiusArticles);
  border: 1px solid var(--Border);
  padding: var(--paddingArticles);
}

.educationArticle h2{
  font-size: 22px;
  font-weight: 500;
}

.educationContainerText{
  display: flex;
  flex-direction: column;
  gap: 5px;
  
}

.edBox{
  display: flex;
  border-bottom: 1px solid var(--Border);
  padding: 10px 0 10px 0;
}

.imgEducation{
  width: 50px;
  height: 50px;

  max-width: 50px;
  min-height: 50px;
  margin-right: 5px;

}

.imgEducation img{
  width: 100%;
}

.textEducation{
  padding-left: 4px;
}

.textEducation h3{
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.verCredencial{
  border: 1px solid #404040;
  color: #404040;
  padding: 5px 10px;
  padding-bottom: 6px;
  border-radius: 22px;
  background-color: #fff;
  font-size: 17px;
  font-weight: bold;
  margin-top: 5px;
  cursor: pointer;
}


.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContentEd {
  background: var(--BG);
  padding: 15px;
  border-radius: 12px;
  max-width: 900px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: fadeIn 0.5s ease;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  overflow-y: auto;
  gap: 5px;

}

.imgModal{
}

.imgModal img{
  width: 100%;
}

.contentBtnCloseATitleEd{
  background-color: var(--bgWhite);
  padding: 15px;
  border: 1px solid var(--Border);
  border-radius: var(--BradiusArticles);
  position: relative;
}

.contentBtnCloseATitleEd h3{
  font-weight: 500;
}

.btnEd{

  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  padding: 4px 15px 5px 15px;
  border: none;
  color: #fff;
  border-radius: 15px;
  background-color: var(--btnBlue);
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 10px;
  right: 10px;
}


@media screen and (max-width: 1386px) {
  :root {
    --BG: #f4f2ee;
    --Border: #dfdeda;
    --TreeCTP: #666666;
    --WidthArticles: 95%;
  }

  .contantModalProject {
    width: 90%;
  }


}


@media screen and (max-width: 582px) {
  .CT {
    flex-wrap: wrap;
    gap: 10px;
    padding-left: 0;

  }

  .carouselImagesContainer{
    height: 200px;
  }

  .Stack h2 {
    padding-bottom: 2px;
    margin-top: -5px;
  }

  :root {
    --paddingArticles: 15px;
  }

  .BoxP {
    padding-bottom: 45px;
  }

  .demoProjectsA {
    left: 0;
    right: inherit;

  }

  .presentationHeader {
    position: relative;
  }

  .presentationLogos {
    position: absolute;
    top: 10px;
    right: 16px;
    width: 60%;

  }

  .carousel {
    width: 100%;
  }

  .carouselButton {
    font-size: 14px;
    height: 30px;
    width: 30px;
  }

}